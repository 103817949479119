import s from './s.module.scss'
import diamond from '../../../../img/diamond.svg'
import {Link} from 'react-router-dom'
import { AIRDROP } from '../../../../consts'
import { FC } from 'react'
import { cx } from '../../../../utils'

type Props = {
  className?: string
}

export const Airdrop: FC<Props> = ({className}) => {

  return (
    <Link to={AIRDROP} className={cx(className, s.buttonAirdrop )}>
          <img src={diamond} alt="diamond" width="28" height="24" />
          <span>Airdrop</span>
      </Link>
  )
}
