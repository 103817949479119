export * from './cx'

export const handleMobileScreen = () => {
  const mediaQueryList = window.matchMedia('screen and (max-width: 767px)');
  return mediaQueryList.matches;
};

export const detectKiwiBrowser = () => (
  //@ts-ignore
  window.chrome && window.chrome.app &&
  navigator.userAgent.indexOf('Android') > -1
);
