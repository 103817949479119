export const stargateTheme = {
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
    unit: 'px',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#6e42ca',
      light: '#b3a0fc',
      contrastText: '#000000',
    },
    secondary: {
      main: '#31314e',
      light: '#45456e',
      contrastText: '#FFFFFF',
    },
    info: {
      main: '#4CB3D4',
    },
    success: {
      main: '#2DD8A7',
    },
    error: {
      main: '#F44336',
    },
    warning: {
      main: '#ED8B00',
    },
    text: {
      primary: '#FAFAFA',
      secondary: '#999A9A',
      // disabled: '#9A9A9A',
    },
    divider: 'rgba(250, 250, 250, 0.12)',
    background: {
      paper: '#1d1d2f',
      default: '#16162d',
    },
  },
  shape: {
    borderRadius: 20,
  },
  typography: {
    fontFamily: '"lft-etica-mono", "Roboto Mono", monospace',
  },
};
