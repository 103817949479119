import { KanaWidget, AptosDexsFilter, BridgeOption, Chain, WidgetConfig } from "@kanalabs/kana-widget";
import React, { useEffect } from "react";
import CoinsRegistry from '@pontem/coins-registry';

import kanaLogo from "../../img/kana_logo.svg";
import pontemLogo from "../../img/logo_white.svg";

const MAINNET = 'mainnet';

const sourceNetworkIds = {
  solana: 1,
  aptos: 2,
  polygon: 3,
  bsc: 4,
  sui: 5,
  ethereum: 6,
  base: 7,
  klaytn: 8,
  zkSync: 9,
  Avalanche: 10,
  Arbitrum: 11
};

const pageTitle = 'Cross-Chain Swap by Kana labs & Pontem';

const KanaWidgetWrapper = () => {

  // TODO: move from coin-registry to api call for coins.
  // @ts-ignore
  const aptosTokensAddresses = CoinsRegistry.getCoinsFor(MAINNET).filter(coin => !coin?.unsafe).map(coin => coin.type);
  const SDK_API_KEY = process.env.SDK_API_KEY || 'rxKSMebYxBEuWAf8TovnhwP9d5BY4KbYH3kS2jhzRD';
  const config: WidgetConfig = {
    //sdk api key for integrator
    sdkKey: SDK_API_KEY,
    //integrator to track
    integrator: "pontem",
    tokens:{
      // Tokens configuration for Chain.Aptos
      [Chain.Aptos]: aptosTokensAddresses
    },
    chains: {
      // set source chain to Aptos and Eth
      sourceChain: [sourceNetworkIds.ethereum, sourceNetworkIds.aptos, sourceNetworkIds.solana],
      // set source chain to Aptos and Eth
      targetChain: [sourceNetworkIds.ethereum, sourceNetworkIds.aptos, sourceNetworkIds.solana],
    },
    //default production
    // SDK: {
    //   Environment: Environment.production,
    // },
    dexs: {
      aptos: [AptosDexsFilter.Pontem], // List of DEXs to be used on the Aptos blockchain.
    },
    bridges: [BridgeOption.CCTP, BridgeOption.Wormhole, BridgeOption.LayerZero],
    //default public rpc or integrator can provide ther rpc service
    Provider: {
      aptosProvider: `https://api.mainnet.aptoslabs.com/v1`,
      ethereumProvider: `https://eth.proxy.pontem.network`,
    },
    // visual customization
    containerStyle: {
      backgroundColor: "#1E1123",
      primaryColor: "#130E18",
      secondaryColor: "#2c2533",
      buttonColor: "#6e42ca",
    },
  };

  useEffect(() => {
    document.title = pageTitle;
  }, [])

  return (
    <div className='bridge-wrapper'>
      <div className='bridge-logos'>
        <img className='bridge-logos__image' src={kanaLogo} alt="kana logo"/>
        <span className='bridge-logos__text'>x</span>
        <img className='bridge-logos__image' src={pontemLogo} alt="pontem logo"/>
      </div>
      <KanaWidget config={config} />
    </div>
  );
};

export default KanaWidgetWrapper;


