import { useLayoutEffect, useRef } from "react";
import { stargateTheme } from "../../styles/theme";
import { DownloadWalletBanner } from "../banners";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Main } from "../main";

export const StargateWidget = () => {
  const containerRef = useRef<any>(null);
  const navigateToTutorial = () =>
    window.open(
      "https://pontem.network/posts/how-to-bridge-tokens-to-aptos-buy-apt-on-liquidswap"
    );

  const [storageDownloadValue, setStorageDownloadValue] = useLocalStorage(
    "showDownloadWalletBanner",
    "true"
  );

  useLayoutEffect(() => {
    customElements.whenDefined("aptos-bridge").then(() => {
      if (!window.aptosBridge) {
        return;
      }
      const { config } = window.aptosBridge;

      config.setChains([101, 102, 106, 108, 109, 110, 111]); // see https://layerzero.gitbook.io/docs/technical-reference/mainnet/supported-chain-ids
      // optional
      config.setTokens(["ETH", "WETH", "USDC", "USDT", "APT", "doodoo"]);
      // optional

      config.setWallets(["Pontem", "Martian", "MetaMask", "Petra", "CoinBase"]);
      // @ts-ignore

      if (containerRef.current) {
        containerRef.current.setTheme(stargateTheme);
      }
    });
  }, []);
  return (
    <Main>
      <div>
        <div className="tutorial-container">
          <p>Read the Tutorial before bridging</p>
          <button className="tutorial-button" onClick={navigateToTutorial}>
            Tutorial
          </button>
        </div>
        <div className="widget_parent">
          {/* @ts-ignore */}
          <aptos-bridge ref={containerRef} />
        </div>
        <DownloadWalletBanner />
      </div>
    </Main>
  );
};


declare global {
  interface Window {
    aptosBridge: any;
  }
}
