import React, {ReactElement} from 'react';


interface ILayoutProps {
    children: ReactElement;
}

export const Layout = ({ children }: ILayoutProps) => {
    return (
        <div className='app-container'>
            <div className='app-layout'>
                {children}
            </div>
        </div>
    );
}