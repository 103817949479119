import styles from './styles/downloadBanner.module.scss';
import logosImg from './../../img/pontem-logo.png';
import solanaLogo from './../../img/solana.png';
import ethereumLogo from './../../img/ethereum.png';
import { WALLET_ONELINK } from '../../consts';

export const DownloadWalletBanner = () => {
  return (
    <a
      href={WALLET_ONELINK}
      target="_blank"
      rel="noreferrer noopener"
    >
      <div className={styles.banner}>
        <div className={styles.wrapper}></div>
        <div className={styles.content}>
          <img
            className={styles.logosImage}
            src={logosImg}
            alt="Liquidswap logo"
          />
          <div className={styles.contentWrapper}>
            <div className={styles.contentText1}>Pontem Wallet</div>
            <div className={styles.contentText2}>Now also support Ethereum and Solana</div>
          </div>
          <img
            className={styles.etheriumLogo}
            src={solanaLogo}
            alt="Solana logo"
          />
          <img
            className={styles.solanaLogo}
            src={ethereumLogo}
            alt="Etherium logo"
          />
        </div>
      </div>
    </a>

  );
};
