import { linksList, socialLinks } from "../../consts";

import styles from './footer.module.scss';

import _package from '../../../package.json';

const { version } = _package;

export const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer className={styles.container}>
            <ul className={styles.feedback} />

            <ul className={styles.socialIcons}>
                {socialLinks.map((link) => (
                    <li key={link.label}>
                        <a
                            href={link.url}
                            title={link.label}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img alt={link.label} src={link.icon} />
                        </a>
                    </li>
                ))}
            </ul>

            {linksList.map((links, index) => (
                <ul className={styles.links} key={index}>
                    {links.map((link) => (
                        <li key={link.label}>
                            <a href={link.url} rel="noopener noreferrer">
                                {link.label}
                            </a>
                        </li>
                    ))}
                </ul>
            ))}

            <p className={styles.copyright}>
                &copy; {year} Pontem Technology Ltd. All Rights Reserved. Version Bridge {version} and Widget alpha 27
            </p>
        </footer>
    )
}
