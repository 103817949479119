import { useLocation } from "react-router-dom";

import KiwiLogo from './../../img/kiwi.svg';
import styles from './styles/kiwiBrowserBanner.module.scss';
import { handleMobileScreen, detectKiwiBrowser } from '../../utils';

const oneLink = "https://onelink.to/wp7h6x";

export const KiwiBrowserBanner = ({ onCloseClick }) => {

  const isMobile = handleMobileScreen();

  const isKiwi = detectKiwiBrowser();

  let { pathname } = useLocation();

  function openInNewTab(url) {
    window.open(url, "_blank", "noreferrer");
  }

  if (!isMobile || isKiwi || pathname !== '/') return null;

  return (
    <div className={styles.banner}>
      <img className={styles.image} src={KiwiLogo} alt="Kiwi browser logo"/>
      <h2 className={styles.title}>
        Your current browser can have issues displaying this page. <br/>
        We recommend that you use Kiwi.
      </h2>
      <button className={styles.button} onClick={() => openInNewTab(oneLink)}>Get Kiwi Browser</button>
      <button className={styles.bannerClose} onClick={onCloseClick}>
        <i className="pi pi-times"></i>
      </button>
    </div>
  )
}
