import React, {ReactElement} from 'react';

import pontemLogo from "../../img/logo_white.svg";
import layerZeroLogo from "../../img/layerzero.svg";


interface IMainProps {
    children?: ReactElement;
}

export const Main = ({ children }: IMainProps) => {
    return (
        <div className='page'>
            <div className='bridge'>
                <div className='bridge-logos'>
                    <img className='bridge-logos__image' src={layerZeroLogo} alt="layerZero logo"/>
                    <span className='bridge-logos__text'>x</span>
                    <img className='bridge-logos__image' src={pontemLogo} alt="pontem logo"/>
                </div>
                <p className='bridge-text'>
                    Use LiquidSwap Bridge to transfer an asset across blockchains.
                </p>
                { children ? children : null }
            </div>
        </div>
    )
};